import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import AuthContext from '../../context/AuthContext';
import Teams from '../../components/Teams/WestHants/Teams';
import HeaderWestHantsEdit from '../../components/Header/WestHants/HeaderWestHantsEdit';
import LoginWestHants from '../../components/Auth/WestHants/LoginWestHants';
import ScheduleEdit from '../../components/Schedule/WestHants/ScheduleEdit';


export default function WestHantsEdit() {
    const { user, client, logout } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (user && client !== 'westhants') {
            alert('You do not have access to this page.');
            logout(navigate);
        }
    }, [user, client, logout, navigate]);

    if (!user) {
        return <LoginWestHants />;
    }

    if (client !== 'westhants') {
        return null;
    }

    return (
        <>
            <HeaderWestHantsEdit />
            <br></br>
            <ScheduleEdit />
            <br></br>
            <Teams />
            <footer className="bg-dark text-white text-center py-4">
                <div className="container">
                <p>&copy; 2024 Arena Scheduling. All Rights Reserved.</p>
                </div>
            </footer>
        </>
    )
}